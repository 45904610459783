import React from 'react';
import { graphql } from 'gatsby';
import SanityBlockContent from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Head from '../../components/head';
import Layout from '../../components/layout';
import ProjectList from '../../components/projectList';
import CTA from '../../components/cta';

export default function SingleProjectPage({ data: { project, otherProjects } }) {
  const projects = otherProjects.nodes;
  return (
    <Layout>
      <Head title={`Projekt: ${project.name}`} description={project.caption} />
      <div className="max-w-screen-lg m-auto sm:animate-fade-content-page">
        <h1>
          <span aria-hidden="true" />
          Projekt: {project.name}
        </h1>
        <p className="sm:text-lg">{project.caption}</p>
        <p className="text-sm sm:text-base italic">
          {project.place && <span className="sm:mr-4 block sm:inline">Plats: {project.place}</span>}
          {project.endDate && <span className="sm:mr-4 block sm:inline">Byggår: {project.endDate}</span>}
          {project.size && <span className="sm:mr-4 block sm:inline">Storlek: {project.size}</span>}
        </p>
      </div>
      <div className="max-w-screen-lg m-auto mb-8">
        <GatsbyImage image={project.featuredImage?.asset.gatsbyImageData} alt={project.name} />
        <SanityBlockContent
          blocks={project._rawText}
          renderContainerOnSingleChild
          className="text-lg sm:text-xl antialiased m-auto pt-6"
        />
      </div>
      <div className="m-auto project-images">
        {project.projectImages.map((image) => (
          <div
            className={`mb-4 sm:mb-16 max-w-screen-lg m-auto block ${
              //if aspect ratio is under 1, the image is orientated vertically
              image.asset.metadata?.dimensions.aspectRatio < 1 ? 'sm:max-w-2xl' : 'lg:max-w-6xl xl:max-w-7xl'
            }`}
            key={image.asset.id}
          >
            <GatsbyImage
              image={image.asset.gatsbyImageData}
              alt={image.asset.altText ? image.asset.altText : 'Bild från projektet'}
            />
            {image.caption && (
              <span className="italic text-gray-600 text-base p-4 block bg-gray-100">{image.caption}</span>
            )}
          </div>
        ))}
      </div>
      <CTA image={project.featuredImage?.asset.gatsbyImageData} title={`Blev du inspirerad av ${project.name}?`} />
      <div className="m-auto max-w-6xl py-12 sm:py-20">
        <h2 className="mb-2">
          <span
            className="bg-greenblue block w-8 sm:w-12	h-0.5 sm:h-1  mb-4 rounded-sm sm:animate-fade-content-page"
            aria-hidden="true"
          />
          Flera projekt
        </h2>
        <p className="text-gray-500 mb-6 sm:mb-12">Se några av våra andra projekt</p>
        <ProjectList
          projects={projects}
          className="block sm:grid sm:grid-cols-2 gap-4 m-auto max-w-6xl front-projects"
          parent="front"
        />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String) {
    project: sanityProject(id: { eq: $id }) {
      _rawText(resolveReferences: { maxDepth: 10 })
      name
      caption
      endDate(formatString: "Y")
      place
      size
      featuredImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR)
        }
      }
      projectImages {
        caption
        imgStyle
        asset {
          id
          gatsbyImageData(fit: FILLMAX, placeholder: DOMINANT_COLOR)
          metadata {
            dimensions {
              aspectRatio
            }
          }
        }
      }
    }
    otherProjects: allSanityProject(filter: { id: { ne: $id } }, limit: 4, sort: { fields: _updatedAt, order: DESC }) {
      nodes {
        slug {
          current
        }
        _id
        name
        caption
        featuredImage {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
